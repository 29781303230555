import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FaceIcon from "@mui/icons-material/Face";
import LabelIcon from "@mui/icons-material/Label";
import LinkIcon from "@mui/icons-material/Link";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Images Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("image")}>
          Image Card
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("image_card_actions")}>
          Image Card Actions
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <ListItemText>
          The left drawer of the image inbox contains filter elements that you
          can use to focus the search results.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Search OCR Text"
          secondary="Enter case-insensitive text to search images by the text that was identified during analysis."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Logo Detected"
          secondary="Use this toggle button to show only images where the brand logo was detected during analysis."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Analyze Image"
          secondary="Enter the URL of an image to be analyzed, for a selected brand."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["image"] = el)}
        sx={{ marginTop: 5 }}
      >
        Image Card
      </Divider>
      <ListItem>
        <Typography>
          Each image is represented by a card with the following data:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Title"
          secondary="At the top of the card is the timestamp of when the analysis was performed, and a link to open the full image in a new tab.."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Detail"
          secondary="At the left of the card are the image instructions, with the anticipated infringement detail to the right."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Detail"
          secondary="Below the divider are details: created date, assigned date, assigned to, origin, seller name and link, listing value and links to the listing and the seller. Finally, a row of listing and comment images."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Activity"
          secondary="An expansion panel can be opened with the icon on the left to show a history of activity (comments and photos) that have occurred for this image."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["image_card_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Image Card Actions
      </Divider>
      <ListItem>
        <Typography>
          A number of actions can be taken using buttons and links on the image
          card, as described below:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BrandingWatermarkIcon />
        </ListItemIcon>
        <ListItemText
          primary="Logo"
          secondary="A list of any logos found in the image and their certainty measure."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CenterFocusWeakIcon />
        </ListItemIcon>
        <ListItemText
          primary="Content"
          secondary="A list of entities identified in the image with certainty measure."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <LabelIcon />
        </ListItemIcon>
        <ListItemText
          primary="Label"
          secondary="A list of labels identified in the image with certainty and topicality measures."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FaceIcon />
        </ListItemIcon>
        <ListItemText
          primary="Face"
          secondary="A list of facial features identified in the image."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ContentCopyIcon />
        </ListItemIcon>
        <ListItemText
          primary="Similar"
          secondary="A collection of similar images found on the Internet."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText
          primary="Websites"
          secondary="A list of websites where similar images are found."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ScatterPlotIcon />
        </ListItemIcon>
        <ListItemText
          primary="Cluster"
          secondary="A cluster of similar images found among listings in the system."
        />
      </ListItem>
    </List>
  );
}
