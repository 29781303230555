import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

export default function DeleteConfirmationDialog(props) {
  const { open, handleClose, handleChange, count } = props;

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Listing{count > 1 ? "s" : ""} Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Confirm deletion of ${count} listing${
              count > 1 ? "s" : ""
            }. All open complaints that contain these listings will be impacted.`}
          </DialogContentText>
          <DialogContentText sx={{ mt: 3 }}>
            <Typography variant="subtitle2">
              There is no undo for this action!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleChange}>CONFIRM</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
