import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Dashboard Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("metrics")}>
          Metrics
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("heatmap")}>
          Heatmap
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("charts")}>
          Charts
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("reports")}>
          Reports
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["metrics"] = el)}
        sx={{ marginTop: 5 }}
      >
        Metrics
      </Divider>
      <ListItem>
        <ListItemText>
          There are a number of metric boxes that indicate progress being made
          on listing analysis and filing, based on the filter settings. <br />
          <br /> A quick date range select is found above them where you can
          choose between: all, this year, last year, this month, last month, and
          custom. <br /> <br />
          Below is a description of each metric:
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Listings Removed"
          secondary="The number of listings that have been reviewed by an analyst."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Infringements"
          secondary="The number of listings that have found to be infringing (trademark, copyright, design, etc). There is a detail icon in the upper right that when clicked will display a monthly breakdown of infringments for the previous 6 months."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Infringement Rate"
          secondary="The ratio of infringing listings to those reviewed, displayed as a percentage."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Listings Filed"
          secondary="The number of listings that have been filed in complaints sent to the respective marketplace. A detail icon in the upper right will diplay the number of filed complaints, if you hover your mouse over it."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Compliance"
          secondary="The ratio of listing takedowns to those filed in a complaint, displayed as a percentage. The number of takedowns is also displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Platforms"
          secondary="The number of marketplaces where the listings were identified. The detail icon in the upper right will show a breakdown of grouped marketplace metrics when clicked."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Repeat Infringers"
          secondary="Shows the number of sellers that have had multiple complaints filed against their listings. The detail icon in the upper right will show a table of sellers and their metrics when clicked."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Sellers"
          secondary="The number of distinct sellers for the listings matching the current filter. A detail icon in the upper right will show a table of sellers and their metrics when clicked."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["heatmap"] = el)}
        sx={{ marginTop: 5 }}
      >
        Heatmap
      </Divider>
      <ListItem>
        <ListItemText>
          The Global Infringing Activity map in the center of the dashboard
          displays per-country statistics for the current filter settings.
          Clicking on a country will refocus the dashboard on that country. You
          will find the Advanced Filter country setting is changed and may be
          reset by clicking the country again, or clicking "Clear Filter" on the
          left.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          The following listing metrics are provided in a world map where
          hovering with your mouse over each country will provide details:
          listings reviewed, infringing sellers, infringement rate,
          counterfeiting rate, and compliance rate.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          Additionally, a download icon in the upper right will allow you to
          download a CSV file containing listing data for those that match the
          current filter settings.
        </ListItemText>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["charts"] = el)}
        sx={{ marginTop: 5 }}
      >
        Charts
      </Divider>
      <ListItem>
        <ListItemText>
          Three pie charts and a bar chart are found at the bottom of the
          dashboard.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Most Infringed Products"
          secondary="Hovering the mouse over each segment will reveal the number of products identified in the listings that match the current filter settings."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Most Infringed Product Categories"
          secondary="Hovering the mouse over each segment will reveal the number of product categories identified in the listings that match the current filter settings."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Most Infringed Brands"
          secondary="Hovering the mouse over each segment will reveal the brands identified in the listings that match the current filter settings."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Infringements by Type"
          secondary="Hovering the mouse over each bar in the chart will reveal the number of listings for that infringement type that match the current filter settings."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <ListItemText>
          The left drawer of the dashboard contains several filter elements that
          you can use to focus the dashboard metrics.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Saved Filters"
          secondary="This link at the top left of the filter allows you to select saved filters, or save the current filter settings with a name for later use."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Clear Filter"
          secondary="This link at the top right of the filter allows you to reset all filter settings to their default values."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Created Date"
          secondary="A start for the filter date range, defaulted to the listing created date. By clicking icon at the right of the field, other date fields can be selected for both start and end dates: filing, reviewed, and takedown."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="End Date"
          secondary="The last date (inclusive) for the date range of the filter. The same date field selected by the start date field is used for the end date."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Product"
          secondary="A multi-select of brand products that can be selected. Only those products which have listings are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Platform"
          secondary="A grouped list of marketplace and social media platforms that can be multi-selected. Only those platforms that have listings are displayed."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Advanced Filter
      </Divider>
      <ListItem>
        <ListItemText
          primary="Country"
          secondary="A multi-select of countries that is also controlled by clicking the heatmap. Only those countries where listings are found are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Channel"
          secondary="A multi-select of platform types (channels): website, social media, marketplace, mobile app, NFT, and Metaverse."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["reports"] = el)}
        sx={{ marginTop: 5 }}
      >
        Reports
      </Divider>
      <ListItem>
        <ListItemText>
          There are two downloads available from the dashboard:
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Listings CSV"
          secondary="A comma-separated variable (CSV) file (can be opened in Excel) file containing listing data for the current filter settings can be downloaded from the heatmap by clicking the download icon in the upper right."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="PDF"
          secondary="A PDF of the entire dashboard can be downloaded using the PDF button on the app bar at the top right. The dashboard will switch to light theme, expand all widgets, capture the contents in a file, then return the view to normal."
        />
      </ListItem>
    </List>
  );
}
