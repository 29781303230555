import DashboardIcon from "@mui/icons-material/Dashboard";

const pagesSection = [
  {
    href: "/",
    icon: DashboardIcon,
    title: "Dashboard",
  },
];

const navItems = [
  {
    title: "Views",
    pages: pagesSection,
  },
];

export default navItems;
