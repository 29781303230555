import AddCommentIcon from "@mui/icons-material/AddComment";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DnsIcon from "@mui/icons-material/Dns";
import LocationIcon from "@mui/icons-material/LocationOn";
import LockIcon from "@mui/icons-material/Lock";
import NumbersIcon from "@mui/icons-material/Numbers";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Domains Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("domain")}>
          Domain Card
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("domain_card_actions")}>
          Domain Card Actions
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <ListItemText>
          The left drawer of the domain inbox contains filter elements that you
          can use to focus the search results.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Clear Filter"
          secondary="This link at the top right of the filter allows you to reset all filter settings to their default values."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Search"
          secondary="Enter a valid domain name to search domains. If the domain doesn't exist in the database, it can be added using the Add Domain button below."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["domain"] = el)}
        sx={{ marginTop: 5 }}
      >
        Domain Card
      </Divider>
      <ListItem>
        <Typography>
          Each domain is represented by a card with the following data:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Title"
          secondary="At the top of the card is the domain name and link to open it in a new tab, the main IP address for the domain, and a status indicator for the domain: active, inactive, or redirect."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Graph"
          secondary="The main content of the card is a kiviat graph of key domain statistics, the values of which can be seen by hovering over the point in the graph, or by expanding the detail panel using the button on the lower left (see below)."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["domain_card_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Domain Card Actions
      </Divider>
      <ListItem>
        <Typography>
          A number of actions can be taken using buttons and links on the domain
          card, as described below:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AddCommentIcon />
        </ListItemIcon>
        <ListItemText
          primary="Additional Detail"
          secondary="This button expands a panel that shows the brand(s) associated with this domain. For analysts, there is another button that shows a form for editing takedown data."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <NumbersIcon />
        </ListItemIcon>
        <ListItemText
          primary="Metrics"
          secondary="Shows the detail of metrics gathered for this domain."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AppRegistrationIcon />
        </ListItemIcon>
        <ListItemText
          primary="Registration"
          secondary="Shows the details of the Whois domain registration information."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText
          primary="Certificate"
          secondary="Shows the details of the TLS certificate for the domain."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <DnsIcon />
        </ListItemIcon>
        <ListItemText
          primary="DNS"
          secondary="Shows DNS registration data for the domain, including A record (IP addresses), NS record, and MX (mail) records."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <LocationIcon />
        </ListItemIcon>
        <ListItemText
          primary="Location"
          secondary="Shows a map of the domain location using geolocation of the IP address."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CameraAltIcon />
        </ListItemIcon>
        <ListItemText
          primary="Screenshot"
          secondary="Shows a screenshot of the domain, taken at the time it was added to the system."
        />
      </ListItem>
    </List>
  );
}
