import * as React from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import OpenIcon from "@mui/icons-material/ContentPasteSearch";
import ImageIcon from "@mui/icons-material/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import CommentIcon from "@mui/icons-material/Comment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import IconButton from "@mui/material/IconButton";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import Delete from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CheckIcon from "@mui/icons-material/Check";
import Autocomplete from "@mui/material/Autocomplete";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import FileUpload from "react-material-file-upload";
import useAuth from "../../../hooks/useAuth";
import { getFlag } from "../../../utils/flags";
import { hasRole } from "../../../utils/auth";
import { USDollar } from "../Listing/util";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  INFRINGEMENT_REASONS,
  getToi,
  INVESTIGATION_ORIGIN,
} from "../../../utils/labels";
import {
  useGetUserNamesQuery,
  useGetUserBrandsQuery,
  useGetListingByIdQuery,
  useAddTestPurchaseCommentMutation,
  useDeleteTestPurchaseMutation,
  useUploadPurchaseFileMutation,
  useUpdatePurchaseStatusMutation,
  useAssignTestPurchaseMutation,
  useGetMarketPlacesQuery,
  useGetUsersQuery,
} from "../../../store/api";
import { CircularProgress } from "@mui/material";
import InvestigationForm from "./InvestigationForm";

const allowedFileTypes = [
  "image/png",
  "image/jpeg",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export default function InvestigationCard(props) {
  const { investigation } = props;
  const [comment, setComment] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [addComment, setAddComment] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [confirmReopen, setConfirmReopen] = React.useState(false);
  const [confirmDone, setConfirmDone] = React.useState(false);
  const [addPhotos, setAddPhotos] = React.useState(false);
  const [assign, setAssign] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [assignee, setAssignee] = React.useState(undefined);
  const [inputValue, setInputValue] = React.useState("");
  const [deleteTestPurchase] = useDeleteTestPurchaseMutation();
  const [addTestPurchaseComment] = useAddTestPurchaseCommentMutation();
  const [addingComment, setAddingComment] = React.useState(false);
  const [uploadFiles] = useUploadPurchaseFileMutation();
  const [updateStatus] = useUpdatePurchaseStatusMutation();
  const [assignUser] = useAssignTestPurchaseMutation();
  const { data: users } = useGetUserNamesQuery();
  const [open, setOpen] = React.useState(undefined);
  const [edit, setEdit] = React.useState(false);

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleOpen = (image) => {
    setOpen(image);
  };

  const { user } = useAuth();

  const { data: all_users } = useGetUsersQuery();

  const { data: brands } = useGetUserBrandsQuery(
    user?.auth ? undefined : skipToken
  );

  const { data: fullListing } = useGetListingByIdQuery(
    investigation?.listing ? investigation.listing : skipToken
  );

  const { data: marketplaces } = useGetMarketPlacesQuery(
    user?.auth ? undefined : skipToken
  );

  const brandMatch = (u) => {
    return u.brands?.filter((value) => user.brands?.includes(value)).length > 0;
  };

  const findGroup = (groups) => {
    if (groups.includes("client")) {
      return "Clients";
    } else if (groups.includes("analyst")) {
      return "Analysts";
    }
  };

  const getUserOptions = () => {
    let options = [];
    all_users
      .filter((u) => {
        return (
          brandMatch(u) &&
          (u.groups?.includes("client") || u.groups?.includes("analyst"))
        );
      })
      .forEach((user) => {
        options.push({
          label: user.name,
          value: user.providerId,
          group: findGroup(user.groups),
        });
      });
    return options.sort((a, b) => a.group.localeCompare(b.group));
  };

  const handleDelete = () => {
    deleteTestPurchase(investigation.id);
  };

  const assignTestPurchase = () => {
    assignUser({
      id: investigation.id,
      user: { _id: assignee.value, name: assignee.label },
    });
    setAssign(false);
  };

  const handleCancel = () => {
    updateStatus({ id: investigation.id, status: "CANCELED" });
    setConfirmCancel(false);
  };

  const handleDone = () => {
    updateStatus({ id: investigation.id, status: "COMPLETE" });
    setConfirmDone(false);
  };

  const handleReopen = () => {
    updateStatus({
      id: investigation.id,
      status: investigation.assignedTo ? "ASSIGNED" : "NEW",
    });
    setConfirmReopen(false);
  };

  const handleSubmitComment = () => {
    setAddingComment(true);
    var fd = new FormData();
    files.forEach((file) => {
      fd.append("files", file);
    });
    uploadFiles(fd).then((resp) => {
      let data = {
        id: investigation.id,
        author: user.providerId,
        comment: comment,
        images: resp.data,
      };
      setComment("");
      setFiles([]);
      addTestPurchaseComment(data).then(() => {
        setAddingComment(false);
        setAddComment(false);
      });
    });
  };

  const getUserName = (id) => {
    return all_users && all_users.find((u) => u.providerId === id)?.name;
  };

  const getBrandLogo = (brand) => {
    let logo = brands?.find((b) => b.name === brand)?.logo;
    return logo || "unknown"; // will result in fallback first letter of brand name avatar
  };

  const getBrandAvatar = (purchase) => {
    return (
      <Avatar
        variant="circle"
        alt={purchase.brand}
        src={getBrandLogo(purchase.brand)}
      ></Avatar>
    );
  };

  const getAvatar = () => {
    return (
      <Badge
        badgeContent={investigation.status}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FindInPageIcon color="default" fontSize="large" />
      </Badge>
    );
  };

  const getCreated = () => {
    return (
      <Typography variant="caption">
        Created{": "}
        {investigation.created
          ? `${new Date(investigation.created).toLocaleDateString()} ${
              investigation.created
                ? " by " + getUserName(investigation.requestedBy)
                : ""
            } `
          : ""}{" "}
      </Typography>
    );
  };

  const getAssigned = () => {
    return (
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="caption">
          Assigned{": "}
          {investigation.assignedTo
            ? `${new Date(investigation.assigned).toLocaleDateString()} ${
                investigation.assigned
                  ? " to " + getUserName(investigation.assignedTo)
                  : ""
              } `
            : "Unassigned"}
        </Typography>
        <IconButton size="small" onClick={() => setAssign(!assign)}>
          <EditIcon fontSize={"small"} />
        </IconButton>
      </Stack>
    );
  };

  const getInfringement = (it) => {
    let reason = INFRINGEMENT_REASONS.find((i) => i.value === it);
    if (reason) {
      return `${getToi(reason.toi)} : ${reason.text}`;
    }
    return "Unknown";
  };

  const getOrigin = (it) => {
    let origin = INVESTIGATION_ORIGIN.find((i) => i.value === it);
    if (origin) {
      return `Origin: ${origin.text}`;
    }
    return "";
  };

  const getPlatformName = (code) => {
    return marketplaces[code]?.name || "";
  };

  const getPlatformCountry = (code) => {
    return marketplaces[code]?.country || "";
  };

  const getCommentImage = (comment) => {
    if (comment.images && comment.images.length > 0) {
      return <ImageIcon />;
    } else if (comment.comment.includes("CANCELED")) {
      return <CancelIcon />;
    } else if (comment.comment.includes("COMPLETE")) {
      return <AssignmentTurnedInIcon />;
    } else if (comment.comment.toUpperCase().includes("ASSIGNED")) {
      return <AssignmentIndIcon />;
    }
    return <CommentIcon />;
  };

  const getMarketplaceAvatar = () => {
    return (
      <Link href={fullListing?.url} target="_blank" rel="noopener noreferrer">
        <Tooltip title={getPlatformName(investigation.platform)}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            badgeContent={
              <Avatar
                alt={getPlatformCountry(investigation.platform)}
                sx={{ height: 22, width: 22, border: `2px solid` }}
              >
                {getFlag(getPlatformCountry(investigation.platform))}
              </Avatar>
            }
          >
            <Avatar
              sx={{ height: 42, width: 42, bgcolor: "secondary", mb: 2 }}
              variant="circle"
              alt={investigation.platform}
              src={marketplaces[investigation.platform]?.logo || "unknown"}
            ></Avatar>
          </Badge>
        </Tooltip>
      </Link>
    );
  };

  const allImages = investigation.comments
    ?.flatMap((c) => (c.images && c.images.length > 0 ? c.images : []))
    .filter((i) => i.endsWith("pdf") === false && i.endsWith("pptx") === false);

  const getType = (value) => {
    switch (value) {
      case "PURCHASE":
        return "Test Purchase";
      case "RAID":
        return "Raid";
      case "SEIZURE":
        return "Seizure";
      default:
        break;
    }
    return "Unknown";
  };

  if (all_users) {
    return (
      <Card sx={{ maxWidth: 600 }}>
        <CardHeader
          action={
            <IconButton disabled aria-label="status" sx={{ mt: 2 }}>
              {getAvatar()}
            </IconButton>
          }
          avatar={getBrandAvatar(investigation)}
          title={getType(investigation.type)}
          subheader={`${investigation.brand} : ${investigation.product}`}
        />
        <CardContent sx={{ height: edit ? 860 : 350 }}>
          {!edit && (
            <div>
              {investigation.infringementType && (
                <Stack direction="row" justifyContent="flex-end">
                  <Typography variant="subtitle2" sx={{ mt: -5 }}>
                    {getInfringement(investigation.infringementType)}
                  </Typography>
                </Stack>
              )}
              {
                <Typography>{`Instructions: ${investigation.comment}`}</Typography>
              }
              <React.Fragment>
                <hr style={{ marginTop: 12, marginBottom: 12 }} />
              </React.Fragment>
              <Stack spacing={2} direction="row">
                <Stack
                  spacing={2}
                  direction="column"
                  justifyContent="flex-start"
                  sx={{ width: 300 }}
                >
                  {investigation.due && (
                    <Typography variant="caption">
                      {`Due: ${new Date(
                        investigation.due
                      ).toLocaleDateString()}`}
                    </Typography>
                  )}
                  {!assign && getAssigned()}
                  {all_users && assign && (
                    <div>
                      <Autocomplete
                        disablePortal
                        id="assignment"
                        value={assignee}
                        onChange={(_event, newValue) => {
                          setAssignee(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => {
                          if (value === undefined || value.length === 0) {
                            return false;
                          }
                          return option.value === value.value;
                        }}
                        inputValue={inputValue}
                        onInputChange={(_event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        options={getUserOptions() || []}
                        groupBy={(option) => option.group}
                        renderInput={(params, index) => (
                          <TextField
                            {...params}
                            label="Assign To"
                            key={index}
                          />
                        )}
                      />
                      <IconButton
                        onClick={() => setAssign(false)}
                        color="primary"
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => assignTestPurchase()}
                        color="primary"
                      >
                        <CheckIcon />
                      </IconButton>
                    </div>
                  )}
                  {getCreated()}
                  {investigation.updated && (
                    <Typography variant="caption">
                      {`Updated: ${new Date(
                        investigation.updated
                      ).toLocaleDateString()}`}
                    </Typography>
                  )}
                  {investigation.origin && (
                    <Typography variant="caption">
                      {getOrigin(investigation.origin)}
                    </Typography>
                  )}
                  <Link
                    href={`/listings?value=${fullListing?.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Listing
                  </Link>
                  <Link
                    href={`/sellers?sellerId=${fullListing?.seller}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Seller
                  </Link>
                  {investigation.closed && (
                    <Typography variant="caption">
                      {`Closed: ${new Date(
                        investigation.closed
                      ).toLocaleDateString()}`}
                    </Typography>
                  )}
                  {allImages?.length > 0 && (
                    <ImageList
                      sx={{ height: 70 }}
                      cols={5}
                      rowHeight={70}
                      gap={2}
                    >
                      {allImages?.map((item) => (
                        <ImageListItem
                          key={item}
                          style={{
                            "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
                          }}
                          onClick={() => handleOpen(item)}
                        >
                          <img
                            srcSet={`${item}?fit=crop&auto=format&dpr=2 2x`}
                            src={`${item}?fit=crop&auto=format`}
                            alt={item}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  )}
                </Stack>

                <Stack
                  sx={{ width: 250 }}
                  spacing={1}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  {marketplaces && getMarketplaceAvatar()}
                  {investigation.seller?.name && (
                    <Link
                      href={investigation.seller?.websiteUrl}
                      target="_blank"
                      variant="button"
                      rel="noopener noreferrer"
                      underline="hover"
                    >
                      <Typography variant="subtitle2">
                        {investigation.seller.name}
                      </Typography>
                    </Link>
                  )}
                  {investigation.value && (
                    <Typography>
                      {`Value: ${
                        investigation.value
                          ? USDollar.format(investigation.value)
                          : "Unknown"
                      } ${investigation.currency || "USD"}`}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </div>
          )}

          {edit && hasRole(user, ["god", "admin", "manager", "analyst"]) && (
            <InvestigationForm
              investigation={investigation}
              handleDone={() => setEdit(false)}
            />
          )}
        </CardContent>
        <CardActions disableSpacing sx={{ mt: "auto" }}>
          {!edit && (
            <Tooltip title={"View activity"}>
              <ToggleButton
                size="small"
                value="check"
                aria-label="view activity"
                onClick={() => setExpanded(!expanded)}
                selected={expanded}
              >
                <QuestionAnswerIcon color="primary" fontSize="small" />
              </ToggleButton>
            </Tooltip>
          )}
          {!edit &&
            investigation.status !== "CANCELED" &&
            investigation.status !== "COMPLETE" && (
              <Tooltip title={"Add detail"}>
                <ToggleButton
                  size="small"
                  value="comment"
                  onClick={() => setAddComment(!addComment)}
                  aria-label="add detail"
                  selected={addComment}
                >
                  <AddCommentIcon color="primary" fontSize="small" />
                </ToggleButton>
              </Tooltip>
            )}
          <div style={{ marginLeft: "auto" }}>
            {!confirmDelete &&
              !confirmCancel &&
              !confirmDone &&
              !edit &&
              !confirmReopen && (
                <div>
                  {!edit &&
                    hasRole(user, ["god", "admin", "manager", "analyst"]) && (
                      <Tooltip title={"Edit this investigation"}>
                        <IconButton onClick={() => setEdit(true)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {(investigation.status === "CANCELED" ||
                    investigation.status === "COMPLETE") && (
                    <Tooltip title={"Reopen investigation"}>
                      <IconButton onClick={() => setConfirmReopen(true)}>
                        <OpenIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {investigation.status !== "CANCELED" &&
                    investigation.status !== "COMPLETE" && (
                      <Tooltip title={"Set status to CANCELED"}>
                        <IconButton onClick={() => setConfirmCancel(true)}>
                          <CancelIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {investigation.status !== "COMPLETE" &&
                    investigation.status !== "CANCELED" && (
                      <Tooltip title={"Set status to COMPLETE"}>
                        <IconButton onClick={() => setConfirmDone(true)}>
                          <AssignmentTurnedInIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {hasRole(user, ["god", "admin", "manager"]) && (
                    <Tooltip title={"Delete this investigation"}>
                      <IconButton onClick={() => setConfirmDelete(true)}>
                        <Delete color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              )}
            {confirmDelete && (
              <div>
                <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
                <Button color="secondary" onClick={handleDelete}>
                  Confirm
                </Button>
              </div>
            )}
            {confirmCancel && (
              <div>
                <Button onClick={() => setConfirmCancel(false)}>Cancel</Button>
                <Button color="secondary" onClick={handleCancel}>
                  Confirm
                </Button>
              </div>
            )}
            {confirmDone && (
              <div>
                <Button onClick={() => setConfirmDone(false)}>Cancel</Button>
                <Button color="secondary" onClick={handleDone}>
                  Confirm
                </Button>
              </div>
            )}
            {confirmReopen && (
              <div>
                <Button onClick={() => setConfirmReopen(false)}>Cancel</Button>
                <Button color="secondary" onClick={handleReopen}>
                  Confirm
                </Button>
              </div>
            )}
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ mb: 4 }}>
          <React.Fragment>
            {!investigation.comments && (
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{ mt: 5, ml: 2 }}
              >
                No activity found
              </Typography>
            )}
          </React.Fragment>
          {users &&
            investigation.comments
              ?.slice()
              .reverse()
              .map((c) => (
                <Accordion key={c.timestamp} sx={{ maxWidth: 550 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="comments-content"
                    id="comments-header"
                  >
                    <Stack direction="row" spacing={2}>
                      {getCommentImage(c)}
                      <Typography>
                        {users[c.author]} on{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          dateStyle: "medium",
                          timeStyle: "medium",
                        }).format(new Date(c.timestamp))}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{c.comment}</Typography>
                    {c.images && c.images.length > 0 && (
                      <ImageList
                        sx={{ width: 490, height: 450 }}
                        cols={3}
                        rowHeight={164}
                      >
                        {c.images
                          .filter(
                            (i) =>
                              i.endsWith("pdf") === false &&
                              i.endsWith("pptx") === false
                          )
                          .map((item) => (
                            <ImageListItem key={item}>
                              <img
                                srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                alt={item}
                                loading="lazy"
                              />
                            </ImageListItem>
                          ))}
                      </ImageList>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
        </Collapse>
        <Collapse in={addComment} timeout="auto" unmountOnExit sx={{ p: 5 }}>
          <Typography>
            Enter report comment and optionally add images:
          </Typography>
          <TextField
            label="Comment (required)"
            autoFocus
            multiline
            minRows={2}
            margin="dense"
            id="name"
            value={comment}
            type="text"
            fullWidth
            variant="outlined"
            onChange={(event) => setComment(event.target.value)}
            sx={{ mb: 5, mt: 5 }}
          />
          {!addPhotos && (
            <Button
              variant="outlined"
              startIcon={<AddPhotoAlternateIcon />}
              onClick={() => setAddPhotos(!addPhotos)}
            >
              Add Attachments
            </Button>
          )}
          {addPhotos && (
            <FileUpload
              value={files}
              onChange={setFiles}
              title={"Drop files here or click Select"}
              maxSize={5000000}
              buttonText={"Select"}
              validator={(file) => {
                return allowedFileTypes.includes(file.type)
                  ? null
                  : {
                      code: "invalid-type",
                      message: `Invalid file type: ${file.type}`,
                    };
              }}
            />
          )}
          <Stack direction="row" spacing={2} mt={5} justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => {
                setComment("");
                setFiles([]);
                setAddComment(!addComment);
              }}
              aria-label="cancel comment"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={addingComment}
              disabled={!comment || comment.trim().length < 4}
              sx={{ ml: "auto" }}
              onClick={handleSubmitComment}
              variant="outlined"
            >
              Save
            </LoadingButton>
          </Stack>
        </Collapse>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open !== undefined}
          onClick={handleClose}
        >
          <img src={open} alt={open} />
        </Backdrop>
      </Card>
    );
  } else {
    return <CircularProgress />;
  }
}
