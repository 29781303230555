import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PageviewIcon from "@mui/icons-material/Pageview";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useAuth from "../../hooks/useAuth";
import TestPurchaseDialog from "../../pages/inboxes/Listing/TestPurchaseDialog";
import { useDeleteUserMessageMutation } from "../../store/api";

export default function MessagesTable(props) {
  const { data } = props;
  const { user } = useAuth();
  const [openTestPurchase, setOpenTestPurchase] = React.useState(false);
  const [deleteMessage] = useDeleteUserMessageMutation();

  const rows = [];
  data.forEach((d) => {
    if (d.recipients.includes(user.providerId)) {
      rows.push({
        identifier: d.identifier,
        level: d.level,
        received: new Intl.DateTimeFormat("en-US", {
          dateStyle: "medium",
          timeStyle: "medium",
        }).format(Date.parse(d.timestamp)),
        type: d.type.replaceAll("_", " "),
        message: d.content,
      });
    }
  });

  const handleDelete = (identifier) => {
    deleteMessage(data.find((d) => d.identifier === identifier)._id);
  };

  const handleView = (identifier) => {
    let type = data.find((d) => d.identifier === identifier).type;
    switch (type) {
      case "TEST_PURCHASE":
        setOpenTestPurchase(identifier);
        break;
      case "LISTING_REVIEW":
        window.open(`/listings?value=${identifier}`, "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Level</TableCell>
                <TableCell>Received</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow>
                  <TableCell>
                    <Box sx={{ width: "100%" }} mt={5}>
                      <Typography>No messages</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row) => (
                <TableRow
                  hover
                  key={row.identifier}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.level}
                  </TableCell>
                  <TableCell>{row.received}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.message}</TableCell>
                  <TableCell>
                    <Tooltip title="View detail">
                      <IconButton
                        aria-label="view"
                        size="medium"
                        onClick={() => handleView(row.identifier)}
                      >
                        <PageviewIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => handleDelete(row.identifier)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {openTestPurchase && (
        <TestPurchaseDialog
          handleClose={() => setOpenTestPurchase(undefined)}
          listing={{ testPurchase: openTestPurchase }}
        />
      )}
    </Box>
  );
}
