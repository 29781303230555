import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import { unstable_useId as useId } from "@mui/utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { WrapperVariantContext } from "@mui/lab/internal/pickers/wrappers/WrapperVariantContext";
import dayjs from "dayjs";

const CustomActionBar = (props) => {
  const { dateField, setDateField, actions } = props;
  const wrapperVariant = React.useContext(WrapperVariantContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = useId();

  const actionsArray =
    typeof actions === "function" ? actions(wrapperVariant) : actions;

  if (actionsArray == null || actionsArray.length === 0) {
    return null;
  }

  const menuItems = actionsArray?.map((actionType) => {
    return (
      <MenuItem
        onClick={() => {
          setDateField(actionType);
          setAnchorEl(null);
        }}
        key={actionType}
      >
        {dateField === actionType && (
          <ListItemIcon>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
        )}
        <ListItemText inset={dateField !== actionType}>
          {actionType}
        </ListItemText>
      </MenuItem>
    );
  });

  return (
    <DialogActions>
      <Button
        id={`picker-actions-${id}`}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Date Field
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": `picker-actions-${id}`,
        }}
      >
        {menuItems}
      </Menu>
    </DialogActions>
  );
};

export default function CustomDatePicker(props) {
  const { date, setDate, dateField, setDateField, fields, label, minDate } =
    props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={(newValue) => setDate(newValue, minDate ? "end" : "start")}
        value={typeof date === "number" ? dayjs(date) : date}
        minDate={typeof minDate === "number" ? dayjs(minDate) : minDate}
        label={label || `${dateField} Date`}
        slots={{
          toolbar: CustomActionBar,
          textField: TextField,
        }}
        slotProps={{
          toolbar: {
            actions: fields,
            dateField: dateField,
            setDateField: setDateField,
          },
        }}
      />
    </LocalizationProvider>
  );
}
