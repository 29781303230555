import AddCommentIcon from "@mui/icons-material/AddComment";
import EditIcon from "@mui/icons-material/Edit";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Investigations Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("investigation")}>
          Investigation Card
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton
          onClick={() => scrollToItem("investigation_card_actions")}
        >
          Investigation Card Actions
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <ListItemText>
          The left drawer of the investigation inbox contains several filter
          elements that you can use to focus the search results.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Clear Filter"
          secondary="This link at the top right of the filter allows you to reset all filter settings to their default values."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Created Date"
          secondary="A start for the filter date range, defaulted to the investigation created date. By clicking icon at the right of the field, other date fields can be selected for both start and end dates: assigned, closed, and due."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="End Date"
          secondary="The last date (inclusive) for the date range of the filter. The same date field selected by the start date field is used for the end date."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Status"
          secondary="A multi-select of investigation status options: assigned, canceled, complete, and new."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Assigned To"
          secondary="A multi-select list of users that may be assigned to investigations."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Platform"
          secondary="A grouped list of marketplace and social media platforms that can be multi-selected. Only those platforms that have investigations are displayed."
        />
      </ListItem>
      <Divider sx={{ marginTop: 5 }}>Advanced Filter</Divider>
      <ListItem>
        <ListItemText
          primary="Country"
          secondary="A multi-select of countries to filter investigations, based on the location of the marketplaces. Only those countries where investigations are found are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Search"
          secondary="Enter case-insensitive text to search investigations by instructions and comments."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["investigation"] = el)}
        sx={{ marginTop: 5 }}
      >
        Investigation Card
      </Divider>
      <ListItem>
        <Typography>
          Each investigation is represented by a card with the following data:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Title"
          secondary="At the top of the card is the investigation type along with the brand and product name, separated by a colon. On the right is the current status of the investigation, the options being: new, assigned, complete, and canceled."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Instructions"
          secondary="At the left of the card are the investigation instructions, with the anticipated infringement detail to the right."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Detail"
          secondary="Below the divider are details: created date, assigned date, assigned to, origin, seller name and link, listing value and links to the listing and the seller. Finally, a row of listing and comment images."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Activity"
          secondary="An expansion panel can be opened with the icon on the left to show a history of activity (comments and photos) that have occurred for this investigation."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["investigation_card_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Investigation Card Actions
      </Divider>
      <ListItem>
        <Typography>
          A number of actions can be taken using buttons and links on the
          investigation card, as described below:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AddCommentIcon />
        </ListItemIcon>
        <ListItemText
          primary="Add Comment"
          secondary="This button expands a panel that allows you to add a comment and additionally attachments (photos, PDF, PPT) in the discussion thread for the investigation."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText
          primary="Edit"
          secondary="This button displays a form for editing investigation details."
        />
      </ListItem>
    </List>
  );
}
