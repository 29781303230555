import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
      left: "0px !important",
    },
  },
};

export default function CountrySelect(params) {
  const {
    countryOptions,
    countries,
    classes,
    handleFilterDropdownChange,
    updateFilters,
    size,
  } = params;

  return (
    <FormControl
      sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
      variant="outlined"
      classes={{ root: classes?.customOutline }}
      size={size}
    >
      <InputLabel id="country-multiple-checkbox-label">Country</InputLabel>
      <Select
        labelId="country-multiple-chip-label"
        id="country-multiple-chip"
        multiple
        value={countries}
        onChange={(e) => handleFilterDropdownChange(e, "countries")}
        onClose={() => updateFilters("countries")}
        style={{
          placeholder: {
            color: "purple",
            fontSize: 12,
            fontWeight: "bold",
          },
        }}
        input={<OutlinedInput label=" Country " />}
        renderValue={(selected) => {
          return countryOptions
            .filter((o) => selected.includes(o.value))
            .map((o) => o.text)
            .join(",");
        }}
        MenuProps={MenuProps}
      >
        {countryOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            <Checkbox checked={countries.indexOf(option.value) > -1} />
            <ListItemText primary={option.text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
