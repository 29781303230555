import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Advertisements Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("advertisement")}>
          Advertisement Card
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <ListItemText>
          The left drawer of the advertisement inbox contains several filter
          elements that you can use to focus the search results.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Clear Filter"
          secondary="This link at the top right of the filter allows you to reset all filter settings to their default values."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Created Date"
          secondary="A start for the filter date range, defaulted to the advertisement created date. By clicking icon at the right of the field, other date fields can be selected for both start and end dates: filing, reviewed, and takedown."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="End Date"
          secondary="The last date (inclusive) for the date range of the filter. The same date field selected by the start date field is used for the end date."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Product"
          secondary="A multi-select of brand products that can be selected. Only those products which have advertisements are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Platform"
          secondary="A grouped list of marketplace and social media platforms that can be multi-selected. Only those platforms that have advertisements are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Seller"
          secondary="Enter a seller name to search by in this field. A partial match (not case-sensitive) will also return results."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Infringement"
          secondary="A multi-select for infringement types, including: copyright, trademark, design, counterfeit, cybersquatting, and unauthorized sale."
        />
      </ListItem>
      <Divider sx={{ marginTop: 5 }}>Advanced Filter</Divider>
      <ListItem>
        <ListItemText
          primary="Country"
          secondary="A multi-select of countries to filter advertisements, based on the location of the marketplaces. Only those countries where advertisements are found are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Search"
          secondary="Enter case-insensitive text to search advertisements by title, body, advertisement, or item number."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["advertisement"] = el)}
        sx={{ marginTop: 5 }}
      >
        Advertisement Card
      </Divider>
      <ListItem>
        <Typography>
          Each advertisement is represented by a card with the following data:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Title"
          secondary="At the top of the advertisement card is a brand icon, advertiser name, brand, and product. The advertiser name is a link that will open a new tab with focus on this seller."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Detail"
          secondary="Below the title are the created date, advertisement body, and a list of images from the ad."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Additional Detail"
          secondary="An expansion panel can be opened with a button on the lower right of the card. Here, you will find links to listings that each open in a new tab."
        />
      </ListItem>
    </List>
  );
}
