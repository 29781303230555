import { DialogContentText, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isEmpty } from "lodash";
import * as React from "react";
import useAuth from "../../hooks/useAuth";
import {
  useCrawlRequestMutation,
  useLazyGetProductsByBrandQuery,
  useGetUserBrandsQuery,
} from "../../store/api";

export default function CrawlUrlDialog(props) {
  const { open, handleClose, selected } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useAuth();
  const [url, setUrl] = React.useState("");
  const [validURL, setValidURL] = React.useState(false);
  const [brand, setBrand] = React.useState(selected?.brand || "");
  const [product, setProduct] = React.useState(selected?.product || "");

  const [crawl] = useCrawlRequestMutation();

  const { data: brands } = useGetUserBrandsQuery(
    user?.auth ? undefined : skipToken
  );
  const { data: products } = useLazyGetProductsByBrandQuery(
    !isEmpty(brand) ? { brand: brand } : skipToken
  );

  const handleCrawlRequest = () => {
    crawl({
      product: product,
      brand: brand,
      urls: [url],
    });
    handleClose();
  };

  const validateURL = (url) => {
    try {
      new URL(url);
      setValidURL(true);
    } catch (err) {
      setValidURL(false);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      aria-labelledby="crawl-dialog-title"
    >
      <DialogContent>
        <DialogTitle>Crawl URL Request</DialogTitle>
        <DialogContentText mb={5}>
          Select a brand and product for the provided URL.
        </DialogContentText>
        {brands && (
          <FormControl fullWidth sx={{ mb: 5 }}>
            <InputLabel id="brand-select-label">Brand</InputLabel>
            <Select
              fullWidth
              sx={{ height: 50 }}
              labelId="brand-select-label"
              id="brand-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                setBrand(e.target.value);
                setProduct("");
              }}
            >
              {brands?.map((b, index) => (
                <MenuItem key={index} value={b.name}>
                  <ListItemText primary={b.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth sx={{ mb: 5 }}>
          <InputLabel id="product-select-label">Product</InputLabel>
          <Select
            sx={{ height: 50 }}
            fullWidth
            labelId="product-select-label"
            id="product-select"
            value={product}
            label="Product"
            onChange={(e) => {
              setProduct(e.target.value);
            }}
          >
            {products?.map((b, index) => (
              <MenuItem key={index} value={b.name}>
                <ListItemText primary={b.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              validateURL(e.target.value);
            }}
            required
            id="url-required"
            label="URL"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
        <Button
          onClick={handleCrawlRequest}
          disabled={
            isEmpty(brand) || isEmpty(product) || isEmpty(url) || !validURL
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
