import React from "react";
import SidebarNavList from "./SidebarNavList";

const SidebarNavSection = (props) => {
  const { pages, component: Component = "nav", ...rest } = props;

  return (
    <Component {...rest}>
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};

export default SidebarNavSection;
