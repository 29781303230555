import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CommentIcon from "@mui/icons-material/CommentOutlined";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import InsightsIcon from "@mui/icons-material/Insights";
import LanguageIcon from "@mui/icons-material/Language";
import StoreIcon from "@mui/icons-material/Store";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Listings Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("listing")}>
          Listing Card
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("listing_card_actions")}>
          Listing Card Actions
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("listing_actions")}>
          Listing Actions
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("additional_actions")}>
          Additional Actions
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("howto")}>
          How To Videos
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <ListItemText>
          The left drawer of the listing inbox contains several filter elements
          that you can use to focus the search results.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Saved Filters"
          secondary="This link at the top left of the filter allows you to select saved filters, or save the current filter settings with a name for later use."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Clear Filter"
          secondary="This link at the top right of the filter allows you to reset all filter settings to their default values."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Created Date"
          secondary="A start for the filter date range, defaulted to the listing created date. By clicking icon at the right of the field, other date fields can be selected for both start and end dates: filing, reviewed, and takedown."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="End Date"
          secondary="The last date (inclusive) for the date range of the filter. The same date field selected by the start date field is used for the end date."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Product"
          secondary="A multi-select of brand products that can be selected. Only those products which have listings are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Platform"
          secondary="A grouped list of marketplace and social media platforms that can be multi-selected. Only those platforms that have listings are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Seller"
          secondary="Enter a seller name to search by in this field. A partial match (not case-sensitive) will also return results."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Infringement"
          secondary="A multi-select option for filtering listings by their infringement type. The options are: copyright, trademark, design, counterfeit, cybersquatting, and unauthorized sale."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="State"
          secondary="A multi-select  option for filtering listings by their state. The options are: new, infringing, irrelevant, suspect, authorized seller, lookalike, reviewed, reinstated, genuine, draft, and hold."
        />
      </ListItem>
      <Divider sx={{ marginTop: 5 }}>Advanced Filter</Divider>
      <ListItem>
        <ListItemText
          primary="Country"
          secondary="A multi-select of countries to filter listings, based on the location of the marketplaces. Only those countries where listings are found are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Channel"
          secondary="A multi-select of platform types (channels): website, social media, marketplace, mobile app, NFT, and Metaverse."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Tags"
          secondary="Listings can have tags attached through the listing card. Existing tags are presented in this select for use in filtering the listings inbox."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Search"
          secondary="Enter case-insensitive text to search listings by title, body, seller, or item number."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Listing Status"
          secondary="A multi-select of status values by which to filter listings. Options include: active, inactive, violation removed, and delisted."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Product Category"
          secondary="A multi-select of product categories to filter listings by, based on those found in all listings."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Investigations"
          secondary="A toggle that will allow you to only view listings that have an investigation (test purchase)."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Complaint Status"
          secondary="A button group that will show one of three options: all listings, unfiled listings, and filed complaint listings."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["listing"] = el)}
        sx={{ marginTop: 5 }}
      >
        Listing Card
      </Divider>
      <ListItem>
        <Typography>
          Each listing is represented by a card with the following data:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Title"
          secondary="At the top of the card is the listing title that is a hyperlink to open the original listing in a new tab. To the left is the brand logo. Below the title is the brand and product name, separated by a colon."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Marketplace"
          secondary="At the left of the card are the marketplace logo with country flag badge. Hovering over the logo will reveal the name, in case it's not present or clear. Clicking the logo will open the marketplace in a new tab."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Detail"
          secondary="Listing status, created date, price (converted to USD) and country are found on the left hand side of the card, along with a button that indicates the current infringing status."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Images"
          secondary="On the right side of the card are the listing images. If an image matches a threshold of features for a copyright image, it will be annotated on the image with a button that will display a side-by-side comparison. A button in the upper right of each image will allow for Google Vision analysis of the image."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Additional Detail"
          secondary="An expansion panel can be opened with a button on the lower right of the card. Here, you will find the original listing price/currency, item number, tags, created/reviewed dates, and the full title, body, and textual analysis of the listing."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["listing_card_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Listing Card Actions
      </Divider>
      <ListItem>
        <Typography>
          A number of actions can be taken using buttons and links on the
          listing card, as described below:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText
          primary="Seller"
          secondary="This button opens a seller detail dialog that includes listing stats, location, and scoring information."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText
          primary="Store"
          secondary="This button will open the seller's store on the marketplace in a new tab."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CameraAltIcon />
        </ListItemIcon>
        <ListItemText
          primary="Screenshot"
          secondary="This button opens a dialog with a timestamped list of screenshots taken of the listing, and a button to request an additional screenshot be taken."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FindInPageIcon />
        </ListItemIcon>
        <ListItemText
          primary="Investigation"
          secondary="This button opens a dialog that will let you initiate a test purchase for the listing. You can enter instructions, due date, infringement type, and assign to an individual (optional). If a test purchase exists for the listing, the button icon will be adorned with a green dot."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText
          primary="Comment"
          secondary="This button opens a dialog to view or add comments on the listing. This may be helpful when determining the infringement type. "
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText
          primary="Domain"
          secondary="This button will open the domain statistics for the website in a new tab."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <InsightsIcon />
        </ListItemIcon>
        <ListItemText
          primary="ASIN"
          secondary="If the listing is from Amazon, this button will open a dialog showing the ASIN seller data, including price trends over time for the item."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Similar Listings"
          secondary="In the additional detail section of the listing, there's a link that when clicked will open a collection of listings that have similar textual content as the current listing."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["listing_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Listing Actions
      </Divider>
      <ListItem>
        <Typography>
          Selecting one or more listings with the checkbox on the upper right of
          the card will open a set of actions in the panel on the left. Note you
          can select all visible listings using the checkbox at the top of the
          page. Below are descriptions of the actions available:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Complaint"
          secondary="You can flag the selected listing(s) as one of the following infringement types: copyright, counterfeit, trademark, design, and unauthorized sale."
        />
      </ListItem>
      <ListItem>
        <ListItemText secondary="By default, the toggle switch above these buttons is on, meaning when you select an infringement type, a dialog will be presented for adding a comment with the determination. If you turn it off, listings will be set to your infringement selection without a comment." />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Other (non-complaint)"
          secondary="This select will allow for additional non-complaint classifications of the selected listing(s). The options are: suspect, authorized seller, seller hold, genuine, lookalike, irrelevant, reviewed, and reinstated."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["additional_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Additional Actions
      </Divider>
      <ListItem>
        <Typography>
          On the top navigation bar you will find several buttons with
          additional. Below are descriptions of the actions available:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <RefreshIcon />
        </ListItemIcon>
        <ListItemText
          primary="Refresh"
          secondary="This will reload the current listings from the server. While not normally required, there may be times when you want to reload any changes that may have taken place in the background."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText
          primary="Download"
          secondary="This button will generate a CSV (comma separated values) file (openable in Excel) of listing data for those that match the current filter settings."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <KeyboardIcon />
        </ListItemIcon>
        <ListItemText
          primary="Keyboard Mode"
          secondary="This button opens listings one at a time in a view dedicated to rapidly classifying them using buttons along the bottom (keyboard arrow buttons, optionally)."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CheckBoxIcon />
        </ListItemIcon>
        <ListItemText
          primary="Select All"
          secondary="This checkbox will select all of the listings in the current view, and is used in conjunction with listing classification actions on the left."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Sort"
          secondary="This select in the upper right gives you the ability to sort the query on several fields in either ascending or descending direction."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["howto"] = el)}
        sx={{ marginTop: 5 }}
      >
        How To Videos
      </Divider>
      <ListItem>
        <Typography>
          Below are a number of links to videos that illustrate how to do
          certain tasks within BrandTrax.
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemButton
          component="a"
          href="https://gitlab.com/sipi-ip/dashboard/-/wikis/uploads/cbeb86ff48e597b3baf951661d66af99/Screen_Recording_2023-08-03_at_10.44.18_AM.mov"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemText
            primary="Keyboard Mode"
            secondary="Demonstrates how to enter keyboard review mode and classify listings."
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
}
