import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ScreenshotConfirmationDialog(props) {
  const { open, handleClose, handleChange, count } = props;

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Screenshot Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Confirm screenshot request of ${count} listing${
              count > 1 ? "s" : ""
            }. This process will take place in the background. Only those listings that don't currently have a screenshot, and are not pending, will be impacted.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleChange}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
