import * as React from "react";
import styled from "styled-components/macro";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import HelpIcon from "@mui/icons-material/Help";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { hasRole } from "../../utils/auth";
import useTheme from "../../hooks/useTheme";
import Help from "../Help";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const { user, signOut } = useAuth();
  const [copied, setCopied] = React.useState(false);
  const [openHelp, setOpenHelp] = React.useState(false);
  const { theme, setTheme } = useTheme();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    document.forms["logout-form"].submit();
  };

  return (
    <React.Fragment>
      <form name="logout-form" method="post" action="/logout"></form>
      <Tooltip title="Help">
        <IconButton
          onClick={() => setOpenHelp(!openHelp)}
          color="inherit"
          size="large"
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>

        {user && hasRole(user, ["analyst", "admin", "manager", "god"]) && (
          <CopyToClipboard
            text={user.auth}
            onCopy={() => {
              setCopied(true);
            }}
          >
            <MenuItem>{copied ? "API Key Copied!" : "Copy API Key"}</MenuItem>
          </CopyToClipboard>
        )}
        <MenuItem>
          <Tooltip title="Theme">
            <ToggleButtonGroup
              sx={{ maxHeight: 24 }}
              size="small"
              value={theme}
              exclusive
              onChange={(_e, value) => setTheme(value)}
              aria-label="Platform"
            >
              <ToggleButton value="LIGHT">
                <LightModeIcon sx={{ height: 16 }} />
              </ToggleButton>
              <ToggleButton value="DARK">
                <DarkModeIcon sx={{ height: 16 }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Tooltip>
        </MenuItem>
      </Menu>
      <Help
        open={openHelp}
        close={() => {
          setOpenHelp(false);
        }}
      />
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
