import "react-app-polyfill/stable";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import { ThemeProvider } from "./contexts/ThemeContext";
import { DialogProvider } from "muibox";

import Loader from "./components/Loader";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <Suspense fallback={<Loader />}>
        <DialogProvider>
          <App />
        </DialogProvider>
      </Suspense>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
