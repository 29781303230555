import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Backdrop,
  DialogContentText,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ImageAnalysis from "../../pages/inboxes/Image/ImageAnalysis";
import * as React from "react";
import { useLazyAnalyzeImageQuery } from "../../store/api";

const AnalyzeImageDialog = (props) => {
  const { args, handleClose, listing, listingBrand } = props;

  const [url, setUrl] = React.useState(args.url);
  const [brand, setBrand] = React.useState(listingBrand || "");

  const [trigger, { data, isFetching }] = useLazyAnalyzeImageQuery();

  const handleConfirm = () => {
    trigger({
      url: url,
      listing: listing,
      brand: brand,
    });
  };

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={"sm"}
      scroll="body"
      onClose={handleClose}
      aria-labelledby="Analyze image dialog"
    >
      <DialogContent>
        {isFetching && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isFetching}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {data &&
          data.map((d, index) => {
            return <ImageAnalysis data={d} key={index} />;
          })}
        {!args.url && !data && (
          <React.Fragment>
            <Typography>
              Analyze any image by entering its URL below:
            </Typography>
            <FormControl fullWidth sx={{ mt: 5, mb: 5 }}>
              <TextField
                autoFocus
                fullWidth
                id="commenturl"
                label="Image URL"
                onChange={(event) => setUrl(event.target.value)}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="brand-select-label">Brand</InputLabel>
              <Select
                fullWidth
                labelId="brand-select-label"
                id="brand-simple-select"
                value={brand}
                label="Brand"
                onChange={(e) => setBrand(e.target.value)}
              >
                {args.user.brands.map((b) => (
                  <MenuItem value={b} key={b}>
                    {b}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </React.Fragment>
        )}
        {!data && (
          <DialogContentText variant="button" sx={{ mt: 5 }}>
            {`Please confirm image analysis request (may take several seconds to
          complete)`}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {data ? "Close" : "Cancel"}
        </Button>
        {!data && (
          <Button
            autoFocus
            onClick={handleConfirm}
            disabled={(!args.url && !url) || brand === ""}
          >
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(AnalyzeImageDialog);
