import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Complaints Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("complaint")}>
          Complaint Card
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("actions")}>
          Actions
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <ListItemText>
          The left drawer of the listing inbox contains several filter elements
          that you can use to focus the search results.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Clear Filter"
          secondary="This link at the top right of the filter allows you to reset all filter settings to their default values."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Created Date"
          secondary="A start for the filter date range, defaulted to the listing created date. By clicking icon at the right of the field, other date fields can be selected for both start and end dates: filing and takedown."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="End Date"
          secondary="The last date (inclusive) for the date range of the filter. The same date field selected by the start date field is used for the end date."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Product"
          secondary="A multi-select of brand products that can be selected. Only those products which are in complaints are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Platform"
          secondary="A grouped list of marketplace and social media platforms that can be multi-selected. Only those platforms that have complaints are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Seller"
          secondary="Enter a seller name to search by in this field. A partial match (not case-sensitive) will also return results."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Infringement"
          secondary="A multi-select option for filtering listings by their infringement type. The options are: copyright, trademark, design, counterfeit, cybersquatting, and unauthorized sale."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Status"
          secondary="A multi-select option for filtering complaints by their status. The options are: new, filed, removed, withdrawn, draft, on hold, reinstated."
        />
      </ListItem>
      <Divider sx={{ marginTop: 5 }}>Advanced Filter</Divider>
      <ListItem>
        <ListItemText
          primary="Country"
          secondary="A multi-select of countries to filter complaints, based on the location of the marketplaces. Only those countries where complaints are found are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Search"
          secondary="Enter case-insensitive text to search complaints by seller, description, IPR, or item number."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["complaint"] = el)}
        sx={{ marginTop: 5 }}
      >
        Complaint Card
      </Divider>
      <ListItem>
        <Typography>
          Each complaint is represented by a card with the following data:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Title"
          secondary="At the top of the card is a brand logo, marketplace name, brand and product separated by a colon. At the top right is an icon representing the status of the complaint."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Detail"
          secondary="In the main body of the card you'll find the type of infringement and detail (if applicable). Below the separator line you'll find the complaint created date and Intellectual Property Rights (IPR) detail."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Additional Detail"
          secondary="An expansion panel can be opened with a button on the lower right of the card. Here, you will find a list of sellers and their listings found in the complaint, the complaint description, and a list of images included (optional)."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Actions
      </Divider>
      <ListItem>
        <Typography>
          A number of actions are found on the top application bar and above the
          complaints. Below are descriptions of the actions available:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <RefreshIcon />
        </ListItemIcon>
        <ListItemText
          primary="Refresh"
          secondary="This will reload the current complaints from the server. While not normally required, there may be times when you want to reload any changes that may have taken place in the background."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText
          primary="Download"
          secondary="This button will generate a CSV (comma separated values) file (openable in Excel) of listing data for those that match the current filter settings."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Date Range"
          secondary="This select gives you the ability to quickly select common date ranges, including: all, this year, last year, this month, last month, and custom."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Sort"
          secondary="This select in the upper right gives you the ability to sort the query on several fields in either ascending or descending direction."
        />
      </ListItem>
    </List>
  );
}
