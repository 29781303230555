import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  FormControl,
  InputLabel,
  Select,
  Button,
  Stack,
  TextField,
  MenuItem,
  Divider,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { removeEmptyFields } from "../../../utils/validation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useUpdateInvestigationMutation,
  useGetUsersQuery,
  useGetCountriesQuery,
  useGetUserBrandsQuery,
  useLazyGetProductsByBrandQuery,
} from "../../../store/api";
import dayjs from "dayjs";

export default function InvestigationForm(props) {
  const { investigation, handleDone } = props;
  const {
    control,
    watch,
    getValues,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: investigation,
  });

  const { user } = useAuth();
  const [brandSelected, setBrandSelected] = React.useState(false);
  const [updateInvestigation] = useUpdateInvestigationMutation();
  const { data: all_users } = useGetUsersQuery();
  const { data: countries } = useGetCountriesQuery();
  const { data: brands } = useGetUserBrandsQuery(
    user?.auth ? undefined : skipToken
  );
  const [trigger, { data: products }] = useLazyGetProductsByBrandQuery();
  const [submitting, setSubmitting] = React.useState(false);

  const watchBrand = watch("brand", investigation.brand);

  const onSubmit = (formData) => {
    removeEmptyFields(formData);
    setSubmitting(true);
    updateInvestigation(formData).then(() => {
      setSubmitting(false);
      handleDone();
    });
  };

  React.useEffect(() => {
    if (getValues("brand")) {
      trigger({ brand: getValues("brand") });
      setBrandSelected(true);
    }
    // eslint-disable-next-line
  }, [watchBrand]);

  const brandMatch = (u) => {
    return u.brands?.filter((value) => user.brands?.includes(value)).length > 0;
  };

  const getCountryOptions = () => {
    let options = [];
    Object.keys(countries)
      .sort()
      .forEach((key) => {
        options.push(
          <MenuItem key={key} value={key}>
            {countries[key]}
          </MenuItem>
        );
      });
    return options;
  };

  const getUserOptions = () => {
    let options = [];
    all_users
      .filter((u) => {
        return (
          brandMatch(u) &&
          (u.groups?.includes("client") ||
            u.groups?.includes("analyst") ||
            u.groups?.includes("god"))
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((user) => {
        options.push(
          <MenuItem key={user.providerId} value={user.providerId}>
            {user.name}
          </MenuItem>
        );
      });
    return options;
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Stack
        sx={{ mt: 4 }}
        spacing={4}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Stack
          spacing={3}
          direction="row"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="type-outlined-label" sx={{}}>
              Type
            </InputLabel>
            <Controller
              render={({ field }) => (
                <Select {...field} label="Type" size="medium">
                  <MenuItem value="PURCHASE">Test Purchase</MenuItem>
                  <MenuItem value="RAID">Raid</MenuItem>
                  <MenuItem value="SEIZURE">Seizure</MenuItem>
                </Select>
              )}
              name="type"
              defaultValue={""}
              control={control}
              rules={{
                required: "Type is required",
              }}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="origin-outlined-label" sx={{}}>
              Origin
            </InputLabel>
            <Controller
              render={({ field }) => (
                <Select {...field} label="Origin" size="medium">
                  <MenuItem value="ONLINE_LEAD">Online Lead</MenuItem>
                  <MenuItem value="AACS_ENFORCEMENT_ACTION">
                    AACS Enforcement Action
                  </MenuItem>
                  <MenuItem value="INVESTIGATION">Investigation</MenuItem>
                </Select>
              )}
              name="origin"
              defaultValue={""}
              control={control}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="category-outlined-label" sx={{}}>
              Category
            </InputLabel>
            <Controller
              render={({ field }) => (
                <Select {...field} label="Origin" size="medium">
                  <MenuItem value="FULL_BOTTLE">Full Bottle</MenuItem>
                </Select>
              )}
              name="category"
              defaultValue={""}
              control={control}
            />
          </FormControl>
        </Stack>
        <Stack
          spacing={3}
          direction="row"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {brands && (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="brand-outlined-label" sx={{}}>
                Brand
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field} size="medium" label="Brand">
                    {brands?.map((b, index) => (
                      <MenuItem key={index} value={b.name}>
                        {b.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name="brand"
                defaultValue={""}
                control={control}
                rules={{
                  required: "Brand is required",
                }}
              />
            </FormControl>
          )}
          {brands && (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="product-outlined-label" sx={{}}>
                Product
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field} label="Product" size="medium">
                    {products &&
                      products.map((p, index) => (
                        <MenuItem key={index} value={p.name}>
                          {p.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                name="product"
                defaultValue={""}
                control={control}
                rules={{
                  required: "Product is required",
                }}
              />
            </FormControl>
          )}
        </Stack>
        <Stack
          spacing={3}
          direction="row"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {all_users && (
            <FormControl variant="outlined" sx={{ minWidth: 250 }}>
              <InputLabel id="assigned-outlined-label" sx={{}}>
                Assigned To
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Assigned To"
                    size="medium"
                    disabled={!brandSelected}
                  >
                    {brandSelected ? getUserOptions() : []}
                  </Select>
                )}
                name="assignedTo"
                defaultValue={""}
                control={control}
              />
            </FormControl>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="assigned"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="Assigned"
                  value={value ? dayjs(value) : null}
                  onChange={onChange}
                  slots={{
                    textField: (textFieldProps) => (
                      <TextField {...textFieldProps} size="medium" />
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="performed"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="Performed"
                  value={value ? dayjs(value) : null}
                  onChange={onChange}
                  slots={{
                    textField: (textFieldProps) => (
                      <TextField {...textFieldProps} size="medium" />
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Controller
          name="comment"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Instructions"
              type="text"
              fullWidth
              multiline
              minRows={2}
              size="medium"
              error={!!error}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={error ? error.message : null}
            />
          )}
        />
        <Divider variant="middle" sx={{ width: "100%" }}>
          Value Information
        </Divider>
        <Stack
          spacing={3}
          direction="row"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Controller
            name="price"
            control={control}
            rules={{
              valueAsNumber: true,
              validate: {
                positive: (v) => (v ? parseInt(v) > 0 : true),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Price"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="currency-outlined-label" sx={{}}>
              Currency
            </InputLabel>
            <Controller
              render={({ field }) => (
                <Select {...field} label="Currency" size="medium">
                  {Intl.supportedValuesOf("currency").map((currency) => {
                    return (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              name="currency"
              defaultValue={""}
              control={control}
            />
          </FormControl>
          <Controller
            name="quantity"
            control={control}
            rules={{
              valueAsNumber: true,
              validate: {
                positive: (v) => (v ? parseInt(v) > 0 : true),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Quantity"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="volume"
            control={control}
            rules={{
              valueAsNumber: true,
              validate: {
                positive: (v) => (v ? parseInt(v) > 0 : true),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Volume"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="value"
            control={control}
            rules={{
              valueAsNumber: true,
              validate: {
                positive: (v) => (v ? parseInt(v) > 0 : true),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Value"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Stack>

        <Divider variant="middle" sx={{ width: "100%" }}>
          Seller Information
        </Divider>
        <Controller
          name="seller.name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Seller"
              type="text"
              fullWidth
              size="medium"
              error={!!error}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={error ? error.message : null}
            />
          )}
        />
        <Controller
          name="seller.address"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Seller address"
              type="text"
              fullWidth
              size="medium"
              error={!!error}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={error ? error.message : null}
            />
          )}
        />
        <Stack
          spacing={3}
          direction="row"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Controller
            name="seller.city"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="City"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="seller.state"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="State"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Stack>
        <Stack
          spacing={3}
          direction="row"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {countries && (
            <FormControl variant="outlined" sx={{ minWidth: 270 }}>
              <InputLabel id="country-outlined-label" sx={{}}>
                Country
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <Select {...field} label="Country" size="medium">
                    {getCountryOptions()}
                  </Select>
                )}
                name="seller.country"
                defaultValue={""}
                control={control}
              />
            </FormControl>
          )}
          <Controller
            name="seller.postal"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Postal Code"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Stack>
        <Stack
          spacing={3}
          direction="row"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Controller
            name="seller.whatsApp"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="WhatsApp"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="weChat"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant="outlined"
                label="WeChat"
                type="text"
                fullWidth
                size="medium"
                error={!!error}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        mt={10}
      >
        <Button variant="outlined" onClick={handleDone}>
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          type="submit"
          loading={submitting}
          disabled={!isDirty || !isValid || submitting}
        >
          Save
        </LoadingButton>
      </Stack>
    </form>
  );
}
