import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AnalyzeImageDialog from "../dialogs/AnalyzeImageDialog";
import useAuth from "../../hooks/useAuth";
import { isEqual } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { setImageParams } from "../../store/app";

const ImageFilter = () => {
  const { user } = useAuth();
  const [analyzeImage, setAnalyzeImage] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [logo, setLogo] = React.useState(false);
  const dispatch = useDispatch();
  const params = useSelector((state) => state.app.imageParams);
  var timer;

  const updateFilters = (type) => {
    if (type === "search" && isEqual(search, params.search)) {
      return false;
    } else if (type === "clear") {
      setSearch("");
      dispatch(
        setImageParams({
          ...params,
          field: undefined,
          contains: undefined,
          url: undefined,
          logo: false,
        })
      );
      return false;
    }
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      dispatch(
        setImageParams({
          ...params,
          field: "text",
          contains: search,
          url: undefined,
          logo: logo,
        })
      );
    }, 0);
  };

  React.useEffect(() => {
    updateFilters();
    // eslint-disable-next-line
  }, [logo]);

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", marginTop: "4px", marginBottom: "14px" }}>
        <Stack spacing={2} direction="row">
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                size="small"
                id="outlined-search"
                value={search}
                label="Search OCR Text"
                onChange={(e) => setSearch(e.target.value)}
                variant="outlined"
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    updateFilters("search");
                    ev.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item>
              <FormGroup sx={{ ml: 8 }}>
                <FormControlLabel
                  control={
                    <Switch
                      id="logo"
                      checked={logo}
                      onChange={() => {
                        setLogo(!logo);
                      }}
                    />
                  }
                  label="Logo Detected"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Button
        sx={{ ml: 3, minWidth: "200px" }}
        variant="outlined"
        startIcon={<ImageSearchIcon />}
        onClick={() => setAnalyzeImage(true)}
      >
        Analyze Image
      </Button>
      {analyzeImage && (
        <AnalyzeImageDialog
          args={{
            user: user,
            update: false,
          }}
          listing={undefined}
          handleClose={() => setAnalyzeImage(false)}
        />
      )}
    </React.Fragment>
  );
};

export default ImageFilter;
