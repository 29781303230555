import * as React from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { isEmpty } from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
      left: "0px !important",
    },
  },
};

export default function MarketplaceSelect(params) {
  const { all_marketplaces, marketplaces, setMarketplaces } = params;

  const [marketplaceOptions, setMarketplaceOptions] = React.useState([]);

  const processMarketplaces = (marketplaces) => {
    if (marketplaces !== undefined && !isEmpty(marketplaces)) {
      let opts = [];
      Object.keys(marketplaces)
        .sort()
        .forEach((key) => {
          if (marketplaces[key]) {
            let channel = marketplaces[key]["channel"];
            if (channel === "MARKETPLACE" || channel === "SOCIAL_MEDIA") {
              opts.push({
                text: marketplaces[key]["name"],
                value: key,
                channel: channel,
              });
            }
          }
        });
      setMarketplaceOptions(opts);
    }
  };

  React.useEffect(() => {
    processMarketplaces(all_marketplaces);
  }, [all_marketplaces]);

  const handleChange = async (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("all")) {
      if (value.length === marketplaceOptions.length + 1) {
        setMarketplaces([]);
      } else {
        setMarketplaces(marketplaceOptions.map((m) => m.value));
      }
    } else {
      setMarketplaces(typeof value === "string" ? value.split(",") : value);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="marketplace-multiple-checkbox-label">
        Marketplaces
      </InputLabel>
      <Select
        labelId="marketplace-multiple-checkbox-label"
        id="marketplace-multiple-checkbox"
        multiple
        fullWidth
        value={marketplaces}
        onChange={handleChange}
        input={
          <OutlinedInput id="select-multiple-chip" label=" Marketplace " />
        }
        renderValue={(selected) => {
          return marketplaceOptions
            .filter((o) => selected.includes(o.value))
            .map((o) => o.text)
            .join(", ");
        }}
        MenuProps={MenuProps}
      >
        <ListSubheader>Marketplaces</ListSubheader>
        {marketplaceOptions
          .filter((o) => o.channel === "MARKETPLACE")
          .map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <ListItemIcon>
                <Checkbox checked={marketplaces.indexOf(option.value) > -1} />
              </ListItemIcon>
              <ListItemText primary={option.text} />
            </MenuItem>
          ))}
        <ListSubheader>Social Media</ListSubheader>
        {marketplaceOptions
          .filter((o) => o.channel === "SOCIAL_MEDIA")
          .map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <ListItemIcon>
                <Checkbox checked={marketplaces.indexOf(option.value) > -1} />
              </ListItemIcon>
              <ListItemText primary={option.text} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
