import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import ZoomableSVG from "../../utils/ZoomableSVG";
import Typography from "@mui/material/Typography";
import { Collapse } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useGetCountriesQuery, useGetMarketPlacesQuery } from "../../store/api";

export default function ClusterGraph(props) {
  const { cluster } = props;
  const { user } = useAuth();
  const ref = useRef();

  const width = 500;
  const height = 500;

  const { data: all_marketplaces } = useGetMarketPlacesQuery(
    user?.auth ? undefined : skipToken
  );
  const { data: countries } = useGetCountriesQuery();

  const getMarketplaceName = (mp) => {
    try {
      return all_marketplaces[mp].name;
    } catch (e) {
      return "Unknown";
    }
  };

  const getCountryName = (code) => {
    return countries[all_marketplaces[code].country] || "Unknown";
  };

  const [selectedNode, setSelectedNode] = React.useState(undefined);

  const onClickNode = function (_nodeId, node) {
    setSelectedNode(node.data);
  };

  const getLabel = (d) => {
    return d.name;
  };

  const getColor = () => {
    return "gray";
  };

  const getCard = () => {
    if (selectedNode && selectedNode.id) {
      return (
        <React.Fragment>
          <Typography>
            {`Image used by ${
              selectedNode.id.split("|")[0]
            } on ${getMarketplaceName(
              selectedNode.id.split(":")[0]
            )} (${getCountryName(selectedNode.id.split("|")[1])})`}
          </Typography>
          <OpenInNewIcon
            onClick={() => window.open(selectedNode.ref, "_blank")}
          />
        </React.Fragment>
      );
    } else {
      return <Typography></Typography>;
    }
  };

  const getFill = (node) => {
    switch (node.data.type) {
      case "seller":
        return "#fff";
      case "platform":
        return "#fff";
      case "root":
        return "#222";

      default:
        break;
    }
    return "#666";
  };

  const getItemColor = () => {
    return "gray";
  };

  const getPlatformIcon = (name) => {
    return (
      Object.values(all_marketplaces).find((m) => m.name === name)?.logo || ""
    );
  };

  useEffect(() => {
    const drag = (simulation) => {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    };

    const data = {
      name: "Image Cluster",
      image: cluster.focus,
      type: "root",
      root: true,
      children: [],
    };
    const platforms = new Map();
    if (cluster && all_marketplaces) {
      cluster.items.forEach((item) => {
        let mp = getMarketplaceName(item.identifier.split("|")[1]);
        let platformImages = platforms.get(mp);
        if (!platformImages) {
          platformImages = [];
          platforms.set(mp, platformImages);
        }
        platformImages.push(item);
      });

      for (const [key, value] of platforms) {
        let p = {
          name: key,
          type: "platform",
          children: [],
        };
        value.forEach((l) => {
          p.children.push({
            fontColor: getColor(l),
            type: "image",
            image: l.ref,
            name: l.label,
            root: false,
            id: l.identifier,
          });
        });
        data.children.push(p);
      }

      const root = d3.hierarchy(data);
      const links = root.links();
      const nodes = root.descendants();

      const simulation = d3
        .forceSimulation(nodes)
        .force(
          "link",
          d3
            .forceLink(links)
            .id((d) => d.id)
            .distance(0)
            .strength(1)
        )
        .force("charge", d3.forceManyBody().strength(-1000))
        .force("x", d3.forceX())
        .force("y", d3.forceY());

      // Create the container SVG.
      const svg = d3
        .select(ref.current)
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [-width / 2, -height / 2, width, height])
        .attr("style", "max-width: 100%; height: auto;");

      // Append links.
      const link = svg
        .append("g")
        .attr("stroke", "gray")
        .selectAll("line")
        .data(links)
        .join("line");

      // Append nodes.
      svg
        .append("g")
        .selectAll(".data-group")
        .data(nodes)
        .join((enter) => {
          var g = enter.append("g").attr("class", "data-group");

          g.append("circle")
            .attr("fill", (d) => getFill(d))
            .attr("stroke", (d) => getItemColor(d.data))
            .attr("r", (d) =>
              d.data?.type === "root" ? 6 : d.data?.type === "image" ? 24 : 12
            );

          g.append("text")
            .attr("class", "data-text")
            .attr("fill", (d) => getItemColor(d.data))
            .attr("font-size", 8)
            .text((d) => getLabel(d.data));

          g.append("image")
            .attr("class", "data-image")
            .attr("height", (d) => (d.data.type === "image" ? 44 : 24))
            .attr("width", (d) => (d.data.type === "image" ? 44 : 24))
            .attr("clip-path", "inset(0% round 50%)")
            .attr("xlink:href", (d) => {
              if (d.data?.type === "image") {
                return d.data.image;
              } else if (d.data.type === "platform") {
                return getPlatformIcon(d.data.name);
              } else {
                return "";
              }
            });

          return g;
        })
        .on("click", onClickNode)
        .call(drag(simulation));

      simulation.on("tick", () => {
        link
          .attr("x1", (d) => d.source.x)
          .attr("y1", (d) => d.source.y)
          .attr("x2", (d) => d.target.x)
          .attr("y2", (d) => d.target.y);

        svg
          .selectAll("circle")
          .attr("cx", (d) => d.x)
          .attr("cy", (d) => d.y);

        svg
          .selectAll(".data-group")
          .attr("x", (d) => d.x)
          .attr("y", (d) => d.y);

        svg
          .selectAll(".data-text")
          .attr("x", (d) => (d.data.type === "image" ? d.x + 28 : d.x + 14))
          .attr("y", (d) => d.y);

        svg
          .selectAll(".data-image")
          .attr("x", (d) => (d.data.type === "image" ? d.x - 22 : d.x - 12))
          .attr("y", (d) => (d.data.type === "image" ? d.y - 22 : d.y - 12));
      });
    }

    // eslint-disable-next-line
  }, [all_marketplaces, cluster]);

  return (
    <div>
      <ZoomableSVG width={width} height={height}>
        <svg width={width} height={height} id="graph" ref={ref} />
      </ZoomableSVG>
      <Collapse in={selectedNode !== undefined}>{getCard()}</Collapse>
    </div>
  );
}
