import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import useMediaQuery from "@mui/material/useMediaQuery";
import MessagesTable from "./MessagesTable";
import { useTheme } from "@mui/material/styles";
import { useGetUserMessagesQuery } from "../../store/api";

export default function MessageDialog(params) {
  const { open, handleClose } = params;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { data: messages, isFetching } = useGetUserMessagesQuery({
    pollingInterval: 10000,
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      aria-labelledby="messages-dialog-title"
    >
      <DialogContent>
        <DialogContentText></DialogContentText>
        {messages && <MessagesTable data={messages} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}
