import { Box, Drawer } from "@mui/material";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import Dashboard from "./help/dashboard";
import Listings from "./help/listings";
import Complaints from "./help/complaints";
import Sellers from "./help/sellers";
import Advertisements from "./help/advertisements";
import Investigations from "./help/investigations";
import Domains from "./help/domains";
import Images from "./help/images";

const Wrapper = styled.div`
  width: 350px;
  overflow-x: hidden;
`;

function Help(props) {
  const { open, close } = props;

  const router = useLocation();
  const location = router.pathname;

  const itemRefs = useRef([]);

  const scrollToItem = (index) => {
    itemRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const getContent = () => {
    switch (location) {
      case "/":
        return <Dashboard scrollToItem={scrollToItem} itemRefs={itemRefs} />;
      case "/listings":
        return <Listings scrollToItem={scrollToItem} itemRefs={itemRefs} />;
      case "/complaints":
        return <Complaints scrollToItem={scrollToItem} itemRefs={itemRefs} />;
      case "/sellers":
        return <Sellers scrollToItem={scrollToItem} itemRefs={itemRefs} />;
      case "/ads":
        return (
          <Advertisements scrollToItem={scrollToItem} itemRefs={itemRefs} />
        );
      case "/investigations":
        return (
          <Investigations scrollToItem={scrollToItem} itemRefs={itemRefs} />
        );
      case "/domains":
        return <Domains scrollToItem={scrollToItem} itemRefs={itemRefs} />;
      case "/images":
        return <Images scrollToItem={scrollToItem} itemRefs={itemRefs} />;
      default:
        break;
    }
    return <div>Not found</div>;
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={close}>
        <Wrapper>
          <Box px={4} my={3}>
            {getContent()}
          </Box>
        </Wrapper>
      </Drawer>
    </React.Fragment>
  );
}

export default Help;
