import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Sellers Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("seller")}>
          Seller Card
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("additional_actions")}>
          Additional Actions
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <ListItemText>
          The left drawer of the seller inbox contains several filter elements
          that you can use to focus the search results.
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Clear Filter"
          secondary="This link at the top right of the filter allows you to reset all filter settings to their default values."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Product"
          secondary="A multi-select of brand products that can be selected. Only those products which have sellers are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Platform"
          secondary="A grouped list of marketplace and social media platforms that can be multi-selected. Only those platforms that have sellers are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Seller"
          secondary="Enter a seller name to search by in this field. A partial match (not case-sensitive) will also return results."
        />
      </ListItem>
      <Divider sx={{ marginTop: 5 }}>Advanced Filter</Divider>
      <ListItem>
        <ListItemText
          primary="Country"
          secondary="A multi-select of countries to filter sellers, based on the location of the marketplaces. Only those countries where sellers are found are displayed."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Search"
          secondary="Enter case-insensitive text to search sellers by title, body, seller, or item number."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["seller"] = el)}
        sx={{ marginTop: 5 }}
      >
        Seller Card
      </Divider>
      <ListItem>
        <Typography>
          Each seller is represented by a card with the following data:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Title"
          secondary="At the top of the seller card is an icon that if clicked opens the seller store on its marketplace, the seller identifier, and the marketplace."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Statistics"
          secondary="Below the title are three seller metrics: number of listings, number of complaints, and the number of listing takedowns."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Profile"
          secondary="A table of seller profile data occupies the center of the card and includes: risk score, country, type, repeater, address, brands, and whether they are an authorized seller."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Additional Detail"
          secondary="An expansion panel can be opened with a button on the lower right of the card. Here, you will find WhatsApp and WeChat numbers for the seller and created/updated dates. There are several buttons that open panels to show seller listings, complaints, aliases, image cluster, and network cluster graph."
        />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["additional_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Additional Actions
      </Divider>
      <ListItem>
        <Typography>
          On the top navigation bar you will find several buttons with
          additional. Below are descriptions of the actions available:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <RefreshIcon />
        </ListItemIcon>
        <ListItemText
          primary="Refresh"
          secondary="This will reload the current sellers from the server. While not normally required, there may be times when you want to reload any changes that may have taken place in the background."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Sort"
          secondary="This select in the upper right gives you the ability to sort the query on several fields in either ascending or descending direction."
        />
      </ListItem>
    </List>
  );
}
