import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useUpdateUserMutation } from "../../store/api";

export default function SaveFilterDialog(props) {
  const { params, user, handleClose } = props;
  const [name, setName] = React.useState("");
  const [updateUser] = useUpdateUserMutation();

  const handleChange = () => {
    const filterParams = {};
    if (params.brands.length > 0) {
      filterParams["brand"] = params.brands.join("|");
    }
    if (params.products.length > 0) {
      filterParams["products"] = params.products.join("|");
    }
    if (params.countries.length > 0) {
      filterParams["country"] = params.countries.join("|");
    }
    if (params.categories.length > 0) {
      filterParams["categories"] = params.categories.join("|");
    }
    if (params.marketplaces.length > 0) {
      filterParams["platform"] = params.marketplaces.join("|");
    }
    if (params.infringementTypes.length > 0) {
      filterParams["toi"] = params.infringementTypes.join("|");
    }
    if (params.tags.length > 0) {
      filterParams["tags"] = params.tags.join("|");
    }
    if (params.state?.length > 0) {
      filterParams["state"] = params.state.join("|");
    }
    if (params.seller?.length > 0) {
      filterParams["seller"] = params.seller;
    }
    if (params.start) {
      filterParams["start"] = params.start;
    }
    if (params.end) {
      filterParams["end"] = params.end;
    }
    if (params.filed) {
      filterParams["filed"] = params.filed;
    }
    if (params.dateField?.length > 0) {
      filterParams["dateField"] = params.dateField;
    }
    if (params.field?.length > 0) {
      filterParams["field"] = params.field;
    }
    if (params.value?.length > 0) {
      filterParams["value"] = params.value;
    }
    if (params.testPurchase === true) {
      filterParams["testPurchase"] = params.testPurchase;
    }
    if (params.sortBy?.length > 0) {
      filterParams["sortBy"] = params.sortBy;
    }
    if (params.order?.length > 0) {
      filterParams["order"] = params.order;
    }

    let newFilter = { name: name, params: filterParams };
    updateUser({
      ...user,
      ...{
        filters: user.filters ? user.filters.concat(newFilter) : [newFilter],
      },
    }).then(() => {
      handleClose();
    });
  };

  return (
    <Dialog
      open={params !== undefined}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>Save Filter</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter filter name:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Filter name"
          value={name}
          type="text"
          fullWidth
          variant="standard"
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CANCEL</Button>
        <Button onClick={handleChange}>SAVE</Button>
      </DialogActions>
    </Dialog>
  );
}
